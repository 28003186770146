<template>
  <div class="textbox">
    <div class="topboxinforcc">
      <span
        >欢迎访问：<span style="font-weight: 600">西安浐灞产业联盟</span></span
      >
      <span @click="getindex" style="margin-left: 50px; cursor: pointer"
        >返回平台首页</span
      >
      <span style="float: right; cursor: pointer" @click="getLoginOut"
        >退出登录</span
      >
    </div>
    <div class="topbox">
      <div class="topboxinfor">
        <img
        class="logo"
          src="../assets/img/ccbst.png"
          alt=""
          @click="getindex"
          style="cursor: pointer"
        />
        <div
          class="top_btnbox"
          :class="i == '/companyCenter/personalCenter'|| i=='/companyCenter/addCompant' ? 'active1' : ''"
          @click="getperson"
        >
          <img src="../assets/img/usercenter.png" alt="" />
          <span>个人中心</span>
        </div>
        <div
          v-if="CompanyObj.State == '1'"
          class="top_btnbox"
          :class="
            i == '/companyCenter/centerHome' ||
            i == '/recruitCenter' ||
            i == '/supplyCenter' ||
            i == '/basicCenter' ||
            i == '/detailedCenter' ||
            i == '/financeCenter' ||
            i=='/ZMserverCenter'
           
              ? 'active1'
              : ''
          "
          @click="getCompany"
        >
          <img src="../assets/img/company.png" alt="" />
          <span>企业中心</span>
        </div>
        <div
          v-if="BuildingObj.SysID"
          class="top_btnbox"
          :class="i == '/companyCenter/carrierCenter' ? 'active1' : ''"
          @click="getcarrier"
        >
          <img src="../assets/img/zaiti.png" alt="" />
          <span>载体中心</span>
            <em v-if="redNum && redNum < 99">{{
            redNum
          }}</em>
          <em v-if="redNum >= 99">99+</em>
        </div>
        <div
          class="top_btnbox"
          :class="i == '/companyCenter/newsCenter' ? 'active1' : ''"
          @click="getnews"
        >
          <img src="../assets/img/news.png" alt="" />
          <span>消息中心</span>
          <em v-if="UserObj.NoReadCount && UserObj.NoReadCount < 99">{{
            UserObj.NoReadCount
          }}</em>
          <em v-if="UserObj.NoReadCount >= 99">99+</em>
         
        </div>
      </div>
    </div>
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 1,

      // 企业信息列表
      ruleForm: {},
      // 企业信息列表
      value: "",
      CompanyObj: {},
      UserObj: {},
      BuildingObj: {},
      redNum:0
    };
  },
  computed: {
    i() {
      return this.$route.path.replace("/index", "");
    },
  },
  methods: {
    getradio(e) {
      console.log(e);
    },
    getindex() {
      this.$router.push("/");
    },
        // 联系数量
        getContactsNum() {
      let that = this;
      let compObj = that.BuildingObj
      that
        .$post("/api/Contact/ContactCount", {
          IsHandle: false,
       
          MainSysID:compObj.SysID,
        
        })
        .then(function (data) {
          console.log(data);
        if (data.Result) {
         that.redNum = data.Obj
        }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let CompanyObj = data.Obj.CompanyObj;

          let UserObj = data.Obj.UserObj;
          if (!UserObj) {
            window.localStorage.clear();
            that.$router.push("/");

            return;
          }
          let BuildingObj = data.Obj.BuildingObj;
          that.CompanyObj = CompanyObj;
          that.UserObj = UserObj;
          that.BuildingObj = BuildingObj;
            that.getContactsNum()
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getperson() {
      this.$router.push("/companyCenter/personalCenter");
      this.getnewsList();
      this.getContactsNum()
    },
    getnews() {
      this.$router.push("/companyCenter/newsCenter");
      this.getnewsList();
       this.getContactsNum()
    },
    getcarrier() {
      this.$router.push("/companyCenter/carrierCenter");
      this.getnewsList();
       this.getContactsNum()
    },
    getCompany() {
      this.$router.push("/companyCenter/centerHome");
      this.getnewsList();
       this.getContactsNum()
    },
    getLoginOut() {
      this.$confirm("是否确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.localStorage.clear();
        this.$router.push("/");
      });
    },
  },

  mounted() {
    this.getnewsList();
  
  },
};
</script>
<style scoped  lang="scss">
.home {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.bottom_right ::v-deep .el-select .el-input__inner {
  width: 320px;
}
.htmlbox {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.htmlbox ::v-deep img {
  width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  // margin-top: 30px;
}
.topboxinfor {
  position: relative;
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);

  // background-color: aquamarine;
}
.topboxinforcc {
  position: relative;
  width: 1200px;
  height: 40px;
  line-height: 40px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #888;

  // background-color: aquamarine;
}
.topboxinfor .logo {
 
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}
.top_btnbox {
  position: relative;
  top: 0;
  float: left;
  margin-left: 20px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  // background-color: red;
  em {
    position: absolute;
    
    font-style: normal;
    height: 20px;
    line-height: 20px;
    width: 20px;
    right: 8px;
    top: 8px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  img {
    position: absolute;
    height: 40px;
    width: 40px;
    left: 30px;
    top: 10px;
    margin: 0;
  }
  span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 60px;
    height: 30px;
    line-height: 30px;
    color: #53c3c2;
    font-size: 15px;
  }
}
.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
}
.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  border-left: 3px solid blue;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  // display: inline-block;
  float: left;
  width: 50%;
  padding: 10px 15px;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  color: #63cafd;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    display: inline-block;
    height: 40px;
    width: 120px;
    line-height: 40px;
    color: #666;
    // background-color: #D9EDF7;
  }
  i {
    font-style: normal;
  }
}
.usertitle {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
.userlogo {
  height: 100px;
  // font-weight: 700;

  line-height: 100px;
  font-size: 16px;

  span {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    //  padding: 15px;
    color: #666;
  }
  img {
    height: 100px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
@media (max-width: 500px) {
  .home {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  // .bottom_right ::v-deep .el-select .el-input__inner {
  //   width: 320px;
  // }
  .htmlbox {
    float: left;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .htmlbox ::v-deep img {
    width: 100%;
  }
  .htmlbox ::v-deep p {
    line-height: 30px;
    font-size: 15px;
    color: #555;
  }
  .htmlbox ::v-deep dt {
    line-height: 40px;
    font-weight: 600;
    font-size: 15px;
    color: #555;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .textbox {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f6f6f6;
  }
  .topbox {
    width: 100%;
    background-color: #fff;
    height: 100px;
    // margin-top: 30px;
  }
  .topboxinfor {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);

    // background-color: aquamarine;
    .logo{
      display: none;
    }
  }
  .topboxinforcc {
    // display: none;
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #fff;

    background-color: #53c3c2;
  }
  .topboxinfor img {
    // display: none;
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-right: 40px;
  }
  .top_btnbox {
    position: relative;
    top: 10px;
    float: left;
    margin-left: 0;
    height:90px;
    width: 25%;
    cursor: pointer;
    // background-color: red;
    em {
      position: absolute;
      font-style: none;
      height: 24px;
      line-height: 24px;
      width:24px;
      right: 6;
      top: 6;
      border-radius: 50%;
      background-color: red;
      font-size: 10px;
      color: #fff;
      text-align: center;
    }
    img {
      position: absolute;
      height: 40px;
      width: 40px;
      left: 50%;
      top: 10px;
      margin: 0;
      transform: translateX(-50%);
    }
    span {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50px;
      height: 30px;
      line-height: 30px;
      color: #53c3c2;
      font-size: 15px;
    }
  }

  .tabbox {
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .tabtitle {
    padding-left: 30px;
    box-sizing: border-box;
    height: 50px;
    line-height: 55px;
    font-size: 16px;
  }
  .tabbtn {
    height: 35px;
    line-height: 35px;
    padding-left: 40px;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }
  .active {
     background-color: blue;
  color: #fff;
  }
  .active1 {
    background-color: #e9e9e9;
  }
  .huanying {
    height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    background-color: #d9edf7;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
  }
  .smalltitle {
    position: relative;
    height: 36px;
    line-height: 6px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .heard {
    position: relative;
    height: 30px;
    width: 100%;
    span {
      cursor: pointer;
      position: absolute;
      height: 30px;
      width: 100px;
      border-radius: 3px;
      color: #1890ff;
      font-size: 14px;
      line-height: 32px;
      background-color: #fff;
      vertical-align: middle;
      img {
        display: inline-block;
        height: 30px;
        width: 30px;
        // vertical-align:middle;
        vertical-align: middle;
      }
    }
  }
  .userinforbox {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 15px;
  }
  .username {
    // display: inline-block;
    float: left;
    width: 50%;
    padding: 10px 15px;
    height: 60px;
    line-height: 40px;
    font-size: 14px;
    color: #63cafd;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    span {
      display: inline-block;
      height: 40px;
      width: 120px;
      line-height: 40px;
      color: #666;
      // background-color: #D9EDF7;
    }
    i {
      font-style: normal;
    }
  }
  .usertitle {
    float: left;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    margin-top: 20px;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .userlogo {
    height: 100px;
    // font-weight: 700;

    line-height: 100px;
    font-size: 16px;

    span {
      display: inline-block;
      width: 100px;
      font-size: 14px;
      //  padding: 15px;
      color: #666;
    }
    img {
      height: 100px;
      // width: 80px;
      vertical-align: middle;
      margin-right: 30px;
    }
  }
  .inforedint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #f1f1f1;
    span {
      cursor: pointer;
      background-color: #1890ff;
      padding: 10px 30px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .password {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .left {
    flex: 6;
    .left_title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600;
      padding-left: 15px;
      box-sizing: border-box;
      color: #555;
    }
    .left_text {
      color: #555;
      line-height: 30px;
      font-size: 14px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .right {
    flex: 1;

    span {
      background-color: #1890ff;
      cursor: pointer;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }
}
</style>