var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"textbox"},[_c('div',{staticClass:"topboxinforcc"},[_vm._m(0),_c('span',{staticStyle:{"margin-left":"50px","cursor":"pointer"},on:{"click":_vm.getindex}},[_vm._v("返回平台首页")]),_c('span',{staticStyle:{"float":"right","cursor":"pointer"},on:{"click":_vm.getLoginOut}},[_vm._v("退出登录")])]),_c('div',{staticClass:"topbox"},[_c('div',{staticClass:"topboxinfor"},[_c('img',{staticClass:"logo",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/img/ccbst.png"),"alt":""},on:{"click":_vm.getindex}}),_c('div',{staticClass:"top_btnbox",class:_vm.i == '/companyCenter/personalCenter'|| _vm.i=='/companyCenter/addCompant' ? 'active1' : '',on:{"click":_vm.getperson}},[_c('img',{attrs:{"src":require("../assets/img/usercenter.png"),"alt":""}}),_c('span',[_vm._v("个人中心")])]),(_vm.CompanyObj.State == '1')?_c('div',{staticClass:"top_btnbox",class:_vm.i == '/companyCenter/centerHome' ||
          _vm.i == '/recruitCenter' ||
          _vm.i == '/supplyCenter' ||
          _vm.i == '/basicCenter' ||
          _vm.i == '/detailedCenter' ||
          _vm.i == '/financeCenter' ||
          _vm.i=='/ZMserverCenter'
         
            ? 'active1'
            : '',on:{"click":_vm.getCompany}},[_c('img',{attrs:{"src":require("../assets/img/company.png"),"alt":""}}),_c('span',[_vm._v("企业中心")])]):_vm._e(),(_vm.BuildingObj.SysID)?_c('div',{staticClass:"top_btnbox",class:_vm.i == '/companyCenter/carrierCenter' ? 'active1' : '',on:{"click":_vm.getcarrier}},[_c('img',{attrs:{"src":require("../assets/img/zaiti.png"),"alt":""}}),_c('span',[_vm._v("载体中心")]),(_vm.redNum && _vm.redNum < 99)?_c('em',[_vm._v(_vm._s(_vm.redNum))]):_vm._e(),(_vm.redNum >= 99)?_c('em',[_vm._v("99+")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"top_btnbox",class:_vm.i == '/companyCenter/newsCenter' ? 'active1' : '',on:{"click":_vm.getnews}},[_c('img',{attrs:{"src":require("../assets/img/news.png"),"alt":""}}),_c('span',[_vm._v("消息中心")]),(_vm.UserObj.NoReadCount && _vm.UserObj.NoReadCount < 99)?_c('em',[_vm._v(_vm._s(_vm.UserObj.NoReadCount))]):_vm._e(),(_vm.UserObj.NoReadCount >= 99)?_c('em',[_vm._v("99+")]):_vm._e()])])]),_c('router-view')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("欢迎访问："),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("西安浐灞产业联盟")])])}]

export { render, staticRenderFns }